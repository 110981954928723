.programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}
.programs-header{
    display: flex;
    gap: 5rem;
    font-size: 3rem;
    font-weight: bold;
    color: white;
    font-style: italic;
    justify-content: center;
    text-transform: uppercase;
}

.programs-categories{
    display: flex; 
    gap: 1rem;
}
.category{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    color: white;
    background-color: gray;
    justify-content: space-between;
}
.category>:nth-child(1){
    width: 2rem;
    height: 2rem;
    fill: white;
}

.category>:nth-child(2){
   font-weight: bold;
   font-size: 1rem;
}

.category>:nth-child(3){ 
    font-size: .9rem;
    line-height: 25px;
 }
 .join-now{
    display: flex;
    align-items: center;
    gap: 2rem; 
 }
 .join-now>span>img{
    height: 1rem;
 }
 .category:hover{
    cursor: pointer;
    background: var(--planCard);
 }






 @media(max-width:768px)
 {
    .programs-header{
        justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: x-large;
    gap: 1rem;
    margin-top: 2rem;
    }
    .programs-categories{
        flex-direction: column;
    }



 }