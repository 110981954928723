.header{
    display: flex;
    justify-content: space-between;
}
.logo{
    width: 10rem;
    height: 3rem;
}
.header-m{
    display: flex;
    gap: 2rem;
    color: white;
    list-style: none;
}
.header-m>a>li:hover{
    color: var(--orange);
    cursor: pointer;
    
}











@media(max-width:768px){

    .header-m{
        flex-direction: column;
        background: var(--appColor);
        padding: 2rem;
        right: 2rem;
        position: relative;
        position: fixed;
        z-index: 10;
    }
    .bars{
        width: 1.5rem;
        height: 1.5rem;
        padding: 8px;
        background: var(--appColor);
        border-radius: 5px;
        right: 2rem;
        position: absolute;
        position: fixed;
        z-index: 10
    }





}