.join{
    display: flex;
    gap: 10rem;
    padding: 0 2rem;

}
.left-j{
    position: relative;
    color: white;
    font-size: 3rem;
    font-weight: bold;
}
.left-j>hr{
    width: 10.5rem;
    border-radius: 20%;
    border: 2px solid var(--orange);
    position: absolute;
    margin-top: -.8rem;
}

.right-j{
    display: flex;
    align-items: flex-end;
    justify-content: center;

}
.email-container{
    display: flex;
    gap: 3rem;
    padding: 1rem 2rem;
    background: rgb(95, 95, 95)
}
.email-container>input{
    background-color: transparent;
    color: var(--lightgray);
    border: none;
    outline: none;
}
::placeholder{
    color: var(--lightgray);
}
.btn-j{
    color: white;
    background: var(--orange);
}








@media(max-width:768px){
    .join{
        flex-direction: column;
        gap: 3rem;
    }
    .left-j{
        font-size: xx-large;
    }
    .left-j>:nth-child(2){
        display: flex;
    font-size: 1.5rem;
    gap: 1rem;
    }
    .left-j>:nth-child(3){
        display: flex;
    font-size: 1.5rem;
    gap: 1rem;
    }
}